.header {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #232f3e;
  padding: 0 0%;
  
}
.footer {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #232f3e;
  
  position:fixed;;
  bottom:0;
  left:0;
  right:0;
  margin-top: -180px;
  
  clear: both;
}
.main{
  position: relative;
 min-height: 100vh;
 background-color:#17a2b8;
}
.logo {
  font-size: 1rem;
  color: white;
  font-weight: Normal;
}
.logofooter {
  font-size: 1rem;
  color: white;
  font-weight: bold;
  justify-Content: center;
  bottom:0;
  left:0;
  right:0;
  
}
.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin-left: 2rem;
}

.header a {
  text-decoration: none;
  font-size: 1.5rem;
  color: white;
}
 
.header a:hover,
.header a:active,
.header active {
  color: gray;
}
 

.badge {
  background-color: #cc2062;
  color: white;
  border-radius: 12px;
  padding: 0 1rem;
  margin-left: 0.5rem;
}
.card-body{
  overflow: auto; 
}
.rodeo-navbar{
  overflow: auto; 
}
.navbar{
  overflow: auto; 
}