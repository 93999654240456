body {
 overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.card{
  
  height:350px;
  widows: 400px;
  object-fit: contain;
}
.card-footer bg-white small
{
  
  color:gray;
}